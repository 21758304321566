<!--
  仪表板：新建/编辑文件夹
-->
<template>
  <el-dialog
    :title="`${type} ${$t('report_label_folder')}`"
    :visible="newFolderTo"
    width="712px"
    :before-close="newFolderCancel"
    :close-on-click-modal="false"
  >
    <!-- 文件夹名称及访问权限 -->
    <div class="title_name">
      <el-form ref="form" :model="form" class="demo-form-inline" label-width>
        <el-form-item
          :label="$t('label.foldername')"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('lable.product.please.enter'),
              trigger: ['blur'],
            },
          ]"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.foldervisitlimit')">
          <el-select v-model="form.permission" placeholder>
            <el-option :label="$t('label.readonly')" value="0"></el-option>
            <el-option :label="$t('label.readandwrite')" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加公用仪表板至此文件夹 -->
    <add-common-to-folder
      ref="AddCommonDashboardRef"
      :types="$t('label.version.standard.dashboard')"
      :dashboardInfo="dashboardInfo"
    />
    <!-- 仪表板访问权限 -->
    <access-permissions
      ref="DashboardAccessRef"
      :types="$t('label.version.standard.dashboard')"
      :permissionInfo="permissionInfo"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="newFolderCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button
        type="primary"
        @click="deleteFolder"
        v-show="type === $t('pagecreator_page_button_edit')"
        >{{ $t("button_partner_delete") }}</el-button
      >
      <el-button type="primary" @click="saveDashboardFolder('form')">{{
        $t("label.h5.act.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as request from "../../api.js";
import addCommonToFolder from "@/views/reportDashboardFolder/addCommonToFolder"; // 添加公用仪表板至此文件夹
import accessPermissions from "@/views/reportDashboardFolder/accessPermissions"; // 仪表板访问权限

export default {
  props: {
    newFolderTo: {
      type: Boolean,
      default: false,
    },
    type: {
      // 新建、编辑文件夹
      type: String,
      default: "",
    },
    dashboardfolderid: {
      // 文件夹id，新增时为''
      type: String,
      default: "",
    },
  },
  components: {
    addCommonToFolder,
    accessPermissions,
  },
  data() {
    return {
      form: {
        name: "", // 文件夹名称
        permission: "", // 文件夹读写权限
      },
      dashboardInfo: {
        dashboardList: [], // 未归档公用仪表板
        selectDashboardList: [], // 此文件夹中的仪表板
      },
      permissionInfo: {
        user: "", // 可用共享者
        groupList: [], // 公用小组
        partnerRoleList: [], // 合作伙伴角色
        partnerUserList: [], // 合作伙伴用户
        roleAndSubList: [], // 角色及内部下属
        roleList: [], // 角色
        userList: [], // 用户
        allSelectedRoles: [], //所有已选择的共享者列表
      },
    };
  },
  watch: {
    newFolderTo: function () {
      if (this.newFolderTo) {
        this.form = {}; // 表单置空
        setTimeout(() => {
          this.$refs.AddCommonDashboardRef.form = {
            keyword1: "", // 搜索字段
          };
          this.$refs.AddCommonDashboardRef.tabCurArr = {}; // 清空选中项
          this.$refs.DashboardAccessRef.form = {
            user: "", // 可用共享者
            role: "", // 选择的角色范围
            keyword2: "", // 对共享者的搜索字段
          };
          this.$refs.DashboardAccessRef.allRoles = [];
          this.$refs.DashboardAccessRef.allSelectedRoles = [];
          this.$refs.DashboardAccessRef.tabCurArr = {}; // 清空选中项
        }, 0);
        this.getDashboardFolder(); // 获取文件夹信息
      }
    },
  },
  methods: {
    // 获取文件夹信息
    async getDashboardFolder() {
      let params = {
        id: this.dashboardfolderid,
      };
      let { data } = await request.getDashboardFolder(params);
      this.form = {
        name: data.folder.name,
        permission: data.folder.purview,
      };
      this.dashboardInfo = {
        dashboardList: data.dashboardList,
        selectDashboardList: data.selectDashboardList,
      };
      let allSelectedRoles = [];
      // 获取已选择的所有用户
      if (data.selectGroupList) {
        data.selectGroupList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectPartnerRoleList) {
        data.selectPartnerRoleList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectPartnerUserList) {
        data.selectPartnerUserList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectRoleAndSubList) {
        data.selectRoleAndSubList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectRoleList) {
        data.selectRoleList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectUserList) {
        data.selectUserList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      this.permissionInfo = {
        user: data.folder.viewType, // 可用共享者
        groupList: data.groupList,
        partnerRoleList: data.partnerRoleList,
        partnerUserList: data.partnerUserList,
        roleAndSubList: data.roleAndSubList,
        roleList: data.roleList,
        userList: data.userList,
        allSelectedRoles: allSelectedRoles,
      };
    },
    // 保存报表文件夹
    saveDashboardFolder(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          /*--
            参数名	必选	类型	说明
            id	否	string	文件夹Id
            name	是	string	文件夹名称
            folderType	否	string	文件夹类型
            purview	是	string	文件夹读写权限
            viewType	是	string	文件夹访问权限
            folderUser	否	string	文件夹分配的指定用户,ids
            selectedDashboardIds	否	string	文件夹包含的仪表板ids 
          */
          let folderUser = []; // 文件夹分配的指定用户
          this.$refs.DashboardAccessRef.allSelectedRoles.map((item) => {
            folderUser.push(item.id);
          });
          let selectedDashboardIds = []; // 文件夹包含的仪表板ids
          this.$refs.AddCommonDashboardRef.selectDashboardList.map((item) => {
            selectedDashboardIds.push(item.id);
          });
          let params = {
            id: this.dashboardfolderid,
            name: this.form.name,
            folderType: "lightningdashboard",
            purview: this.form.permission,
            viewType: this.$refs.DashboardAccessRef.form.user,
            folderUser: folderUser.join(","),
            selectedDashboardIds: selectedDashboardIds.join(","),
          };
          await request.saveDashboardFolder(params);
          if (this.dashboardfolderid) {
            this.$message.success(
              this.$i18n.t("vue_label_dashboard_editfoldersuccessfully")
            ); //编辑文件夹成功
          } else {
            this.$message.success(
              this.$i18n.t("vue_label_dashboard_newfoldersuccessfully")
            ); //新建文件夹成功
          }
          this.$emit("saveDashboardFolder");
        } else {
          return false;
        }
      });
    },
    newFolderCancel() {
      this.$emit("newFolderCancel");
    },
    // 删除文件夹
    deleteFolder() {
      let info = {
        id: this.dashboardfolderid,
        name: this.form.name,
        type: this.$i18n.t("report_label_folder"),
      };
      this.$emit("deleteFolder", info);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 500px;
  overflow-y: auto;

  .border_content {
    display: flex;
    align-items: center;
    .left_info,
    .right_infos {
      padding: 7px 0 10px 9px;
      width: 260px;
      height: 283px;
      overflow-y: auto;
      border: 1px solid #d8dde6;
      border-radius: 4px;
      .each_info {
        color: #333333;
        margin-bottom: 2px;
        padding: 2px;
        cursor: pointer;
      }
      .userActive {
        background-color: #c3c4c5;
        color: #fff;
      }
    }
    .middle_butts {
      margin: 0 10px;
      color: #333333;
      display: flex;
      flex-direction: column;
    }
    div {
      margin-bottom: 8px;
    }
    .el-button--mini,
    .el-button--mini.is-round {
      padding: 4px;
      margin-left: 0;
      margin-bottom: 8px;
    }
  }
}
.title_name {
  ::v-deep .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      .el-select {
        width: 120px;
      }
    }
    .el-form-item:first-child {
      margin-right: 69px;
      .el-input {
        width: 261px;
      }
    }
    .el-form-item__error {
      padding-top: 0;
      padding-left: 2px;
    }
  }
}

::v-deep .active {
  background-color: #c3c4c5;
  color: #fff !important;
}
::v-deep .el-form {
  .el-form-item__label {
    line-height: 16px;
    color: #242424;
    text-align: left;
  }
}
::v-deep .el-input__inner {
  height: 28px !important;
  // border: 1px solid #dcdcdc;
}
::v-deep .el-input__icon {
  height: 28px;
  line-height: 28px;
}
</style>