<!-- 右侧表格 -->
<template>
  <div class="dashboardRightTable">
    <div class="bottom_table">
      <el-table
        ref="filterTable"
        :data="newTableData"
        @sort-change="sortChange"
        v-loading="loading"
      >
        <el-table-column
          :label="$t('label.newdashboardpage.name')"
          min-width="80"
          :prop="'name'"
          show-overflow-tooltip
          sortable="custom"
        >
          <template slot-scope="scope">
            <a
              :href="`/#/dashboardObject/dashboardIndex/${scope.row.id}`"
              target="_blank"
              @click.prevent="clickName(scope.row)"
              class="word_style"
            >
              {{ scope.row.name }}</a
            >
          </template>
        </el-table-column>
        <!-- 仪表板所在文件夹 -->
        <el-table-column
          :label="$t('vue_label_dashboard_located')"
          min-width="80"
          :prop="'dashboardfolderid'"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            {{ dashboardfoldername(scope.row.dashboardfolderid) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('label.createby')"
          min-width="80"
          show-overflow-tooltip
          :prop="'creat'"
          sortable
        >
          <template slot-scope="scope">
            {{ creatLastmodifyPerson(scope.row.creat) }}
          </template>
        </el-table-column>
        <!-- label="最后运行时间" -->
        <el-table-column
          :label="$t('vue_label_dashboard_lastruntime')"
          min-width="80"
          :prop="'createdate'"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.createdate }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('label.trigger.lastmodifydate')"
          align="left"
          min-width="80"
          :prop="'lastmodifydate'"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.lastmodifydate }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('label.lasttimemodifier')"
          align="left"
          min-width="80"
          :prop="'lastmodify'"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            {{ creatLastmodifyPerson(scope.row.lastmodify) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('label.quickbooks.button.actions')"
          align="left"
          min-width="60"
        >
          <template slot-scope="scope">
            <el-popover
              placement="bottom-end"
              :visible-arrow="false"
              width="113"
              trigger="hover"
              popper-class="tableClass"
            >
              <el-card class="box-card moreMenus">
                <ul>
                  <!-- 编辑 -->
                  <li 
                    v-if="isEditDashboard(scope.row.dashboardfolderid)"
                    @click="editBtn(scope.row)"
                  >
                    {{ $t("pagecreator_page_button_edit") }}
                  </li>
                  <!-- 重命名 -->
                  <li
                    v-if="isEditDashboard(scope.row.dashboardfolderid)"
                    @click="renameBtn(scope.row, 'rename')"
                  >
                    {{ $t("label.rename") }}
                  </li>
                  <!-- 删除 -->
                  <li
                    v-if="isEditDashboard(scope.row.dashboardfolderid)"
                    @click="deleteBtn(scope.row)"
                  >
                    {{ $t("component_setup_tabs_label_delete") }}
                  </li>
                  <!-- 转移文件夹 -->
                  <li
                    v-if="isEditDashboard(scope.row.dashboardfolderid)"
                    @click="renameBtn(scope.row, 'transfer')"
                  >
                    <!-- 转移文件夹 -->
                    {{ $t("vue_report_dashboard_transfer") }}
                  </li>
                </ul>
              </el-card>
              <svg class="icon img-style" v-show="isEditDashboard(scope.row.dashboardfolderid)" aria-hidden="true" slot="reference">
                <use href="#icon-xiala"></use>
              </svg>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[5, 10, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalnum"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import * as request from "../../api.js";

export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      // 切换仪表板时获取的动态loading
      type: Boolean,
      default: false,
    },
    allFolderList: {
      type: Array,
      default: () => [],
    },
    totalnum: {
      type: Number,
    },
  },
  data() {
    return {
      // countryCode: $cookies.get("countryCode"),
    };
  },
  computed: {
    // 是否可编辑：重命名、转换文件夹
    isEditDashboard() {
      return (dashboardfolderid) => {
        if (this.allFolderList.find((item) => item.id === dashboardfolderid)) {
          return this.allFolderList.find(
            (item) => item.id === dashboardfolderid
          ).isEdit;
        } else {
          return false;
        }
      };
    },
    // 是否可删除
    // isDeleteDashboard() {
    //   return (dashboardfolderid) => {
    //     if (this.allFolderList.find((item) => item.id === dashboardfolderid)) {
    //       return this.allFolderList.find(
    //         (item) => item.id === dashboardfolderid
    //       ).isDelete;
    //     } else {
    //       return false;
    //     }
    //   };
    // },
    // 创建人、修改人字段需要处理
    creatLastmodifyPerson: function () {
      return (value) => {
        if (!value) return;
        return value.split(",")[0];
      };
    },
    // 根据文件夹id获取文件夹name
    dashboardfoldername: function (id) {
      id ? id : "";
      return (id) => {
        // 匹配文件夹信息
        let folderInfo = this.allFolderList.find((item) => item.id === id);
        if (folderInfo) {
          return folderInfo.name;
        }
        return;
      };
    },
  },
  watch: {
    tableData: {
      handler() {
        this.newTableData = JSON.parse(JSON.stringify(this.tableData));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    sortChange(column) {
      this.proptype = column.prop;
      if (column.order === "descending") {
        this.order = -1;
        this.newTableData.sort(this.sortDevName);
      } else if (column.order === "ascending") {
        this.order = 1;
        this.newTableData.sort(this.sortDevName);
      } else {
        this.newTableData = JSON.parse(JSON.stringify(this.newTableData));
      }
      this.$forceUpdate();
    },
    sortDevName(str1, str2) {
      let res = 0;
      str1[this.proptype] = String(str1[this.proptype]);
      str2[this.proptype] = String(str2[this.proptype]);
      if (str1[this.proptype] !== "" && str2[this.proptype] === "") {
        return -1;
      } else if (str2[this.proptype] !== "" && str1[this.proptype] === "") {
        return 1;
      } else {
        for (let i = 0; ; i++) {
          if (!str1[this.proptype][i] || !str2[this.proptype][i]) {
            res = str1[this.proptype].length - str2[this.proptype].length;
            break;
          }
          const char1 = str1[this.proptype][i];
          const char1Type = this.getChartType(char1);
          const char2 = str2[this.proptype][i];
          const char2Type = this.getChartType(char2);
          // 类型相同的逐个比较字符
          if (char1Type[0] === char2Type[0]) {
            if (char1 === char2) {
              continue;
            } else {
              if (char1Type[0] === "zh") {
                res = char1.localeCompare(char2);
              } else if (char1Type[0] === "en") {
                res = char1.charCodeAt(0) - char2.charCodeAt(0);
              } else {
                res = char1 - char2;
              }
              break;
            }
          } else {
            // 类型不同的，直接用返回的数字相减
            res = char1Type[1] - char2Type[1];
            break;
          }
        }
      }
      res = this.order * res;
      return res;
    },
    getChartType(char) {
      // 数字（0->9）->大写字母（A->Z）->小写字母（a->z）->中文拼音（a->z）
      if (/^[\u4e00-\u9fa5]$/.test(char)) {
        return ["zh", 300];
      }
      if (/^[a-zA-Z]$/.test(char)) {
        return ["en", 200];
      }
      if (/^[0-9]$/.test(char)) {
        return ["number", 100];
      }
      return ["others", 999];
    },
    // 显示重命名仪表板弹框
    updateDashBoard(item, type) {
      this.$emit("updateDashBoard", item, type);
    },
    // 点击仪表板后添加至最近查看
    async addDashboardRecentItem(item) {
      /*--
        id	是	string	仪表板id
        name	是	string	仪表板名称
      */
      let params = {
        id: item.id,
        name: item.name,
      };
      await request.addDashboardRecentItem(params);
    },
    // 点击仪表板名称
    clickName(item) {
      this.addDashboardRecentItem(item);
      this.$router.push({
        path: `/dashboardObject/dashboardIndex/${item.id}`,
        query: {},
      });
    },
    // 点击编辑
    editBtn(item) {
      this.clickName(item);
    },
    // 点击重命名
    renameBtn(item, type) {
      this.updateDashBoard(item, type);
    },
    // 点击删除
    deleteBtn(item) {
      let info = {
        id: item.id,
        name: item.name,
        type: this.$i18n.t("label.version.standard.dashboard"),
      };
      this.$emit("deleteDashBoardAlert", info);
    },
    //切换每页显示条数，5，10，30
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    //切换当前显示页数，1，2，3，4
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/table.scss";

.dashboardRightTable {
  width: calc(100% - 250px);
  background-color: #fff;
  .top_search {
    height: 50px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cbc6c6;
    .left_info {
      color: #666666;
      display: flex;
      align-items: center;
    }
    .right_search_butt {
      display: flex;
      align-items: center;
      ::v-deep .el-input {
        margin-right: 12px;
        width: 264px;
        height: 30px;
        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
        .el-input__icon {
          height: 30px;
          line-height: 30px;
        }
      }
      .all_butts {
        display: flex;
        .el-button--primary {
          padding: 7px 12px;
          border-radius: 3px;
          background-color: #006dcc;
          border-color: #006dcc;
        }
      }
    }
  }
  .img-style {
    width: 19px;
    height: 19px;
  }
  .bottom_table {
    height: 100%;
    ::v-deep .el-table {
      height: calc(100% - 66px);
      i {
        cursor: pointer;
      }
      .word_style {
        color: #006dcc;
        cursor: pointer;
      }
    }
    ::v-deep .el-table__body-wrapper {
      height: calc(100% - 51px);
      overflow-y: auto;
    }
    //滚动条
    ::v-deep ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::v-deep ::-webkit-scrollbar-thumb {
      //滑块部分
      border-radius: 0;
      background: #dedcda !important;
      -webkit-box-shadow: none !important;
    }
    ::v-deep ::-webkit-scrollbar-track {
      //轨道部分
      border-radius: 0;
      background: #f1f1f1 !important;
      -webkit-box-shadow: none !important;
    }
    ::v-deep .el-table::before {
      background-color: transparent !important;
    }
    .el-pagination {
      height: 66px;
      padding-left: 15px;
      background: #fff;
      box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>

<style lang="scss">
.el-popover.tableClass {
  padding: 0 !important;
  // margin: 0 0 0 90px;
  min-width: 113px;
  ::v-deep img {
    width: 15px !important;
    height: 15px !important;
  }
  .moreMenus {
    // width: 113px;
    .el-card__body {
      padding: 10px;
      ol,
      ul,
      dl {
        margin-bottom: 0;
      }
      li {
        height: 30px;
        line-height: 30px;
        &:hover {
          color: #006dcc;
          cursor: pointer;
        }
        .el-button {
          color: #303133;
        }
        .el-button.is-disabled.el-button--text {
          color: #c0c4cc;
        }
      }
    }
  }
}
</style>