<!--
  仪表板列表-左侧菜单
-->
<template>
  <div class="dashboardLeftMenu">
    <!-- 仪表板 -->
    <div class="all_dashboards">
      <div class="top_title_dashboard">
        <div>{{ $t("label.version.standard.dashboard") }}</div>
      
      </div>
      <div class="all_menus_dashboard" v-show="dashFolderFlag">
        <div
          class="each_menu"
          v-for="(item, index) in FolderList"
          :key="index"
          :class="{ active: isactive == item.id }"
          @click="getdashboardfolderid(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 文件夹 -->
    <div class="all_folders">
      <div class="top_title_folder">
        <div>{{ $t("report_label_folder") }}</div>
      </div>
      <div class="all_menus_folder">
        <!-- 固定的文件夹 -->
        <div class="top_folder_datas" v-if="sortfolderList.length > 0">
          <div
            class="each_folder"
            v-for="(item, index) in sortfolderList"
            :key="index"
          >
            <div
              @click="getdashboardfolderid(item)"
              :class="{ active: isactive == item.id }"
            >
              {{ item.name }}
            </div>
            <div
              class="operation_img"
              v-show="
                item.name != $t('label.myselfdashboard') &&
                item.name != $t('label.publicdashboard')
              "
            >
              <!-- 操作图片 -->
              <el-popover
                placement="bottom-start"
                :visible-arrow="false"
                width="113"
                trigger="hover"
                popper-class="folderPopover"
              >
                <svg class="icon" aria-hidden="true" slot="reference">
                  <use href="#icon-xiala"></use>
                </svg>
                <!-- 操作弹框 -->
                <div class="folder_operation">
                  <el-button
                    v-if="index !== 0"
                    @click="setTopFolder(item.id)"
                    >{{ $t("label.reportlist.top") }}</el-button
                  >
                  <el-button @click="cancelTopFolder(item.id)">{{
                    $t("label.reportlist.untop")
                  }}</el-button>
                  <el-button
                    :disabled="!item.isEdit"
                    @click="editFolder(item.id)"
                    >{{ $t("label.weixin.detail.edit") }}</el-button
                  >
                  <el-button
                    :disabled="!item.isDelete"
                    @click="deleteFolder(item)"
                    >{{ $t("button_partner_delete") }}</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <!-- 未固定的文件夹 -->
        <div class="bottom_folder_datas" v-if="nosortfolderList.length > 0">
          <div
            class="each_folder"
            v-for="(item, index) in nosortfolderList"
            :key="index"
          >
            <div
              @click="getdashboardfolderid(item)"
              :class="{ active: isactive == item.id }"
            >
              {{ item.name }}
            </div>
            <div
              class="operation_img"
              v-show="
                item.name != $t('label.myselfdashboard') &&
                item.name != $t('label.publicdashboard')
              "
            >
              <el-popover
                placement="bottom-start"
                :visible-arrow="false"
                width="113"
                trigger="hover"
                popper-class="folderPopover"
              >
                <svg class="icon" aria-hidden="true" slot="reference">
                  <use href="#icon-xiala"></use>
                </svg>
                <div class="folder_operation">
                  <el-button @click="setTopFolder(item.id)">{{
                    $t("label.reportlist.top")
                  }}</el-button>
                  <el-button
                    :disabled="!item.isEdit"
                    @click="editFolder(item.id)"
                    >{{ $t("label.weixin.detail.edit") }}</el-button
                  >
                  <el-button
                    :disabled="!item.isDelete"
                    @click="deleteFolder(item)"
                    >{{ $t("button_partner_delete") }}</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div v-else class="no-data">{{ $t("label.dashboard.noRecord") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as DashboardApi from "../../api.js";

export default {
  props: {
    selectContent: {
      type: String,
      default: "",
    },
    selectType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dashFolderFlag: true, //最近文件是否展开
      isactive: "recentDashboard", //点击添加样式
      // topCur: "",
      nosortfolderList: [],
      sortfolderList: [],
      allFolderList: [], // 所有文件夹
      FolderList: [
        {
          name: this.$i18n.t("Dashboard_View_RecentlyView"),
          id: "recentDashboard",
        },
        {
          name: this.$i18n.t("Dashboard_View_Createdbyme"),
          id: "thedashboardicreated",
        },
        { name: this.$i18n.t("label.dashboard.all"), id: "" },
      ],
    };
  },
  mounted() {
    this.getDashboardFolderList();
  },

  methods: {
    //获取文件夹列表列表接口
    async getDashboardFolderList() {
      var params = {
        searchKeyWord: this.selectContent,
      };
      let res = await DashboardApi.getDashboardFolderList(params);
      this.nosortfolderList = res.data.nosortfolderList;
      this.sortfolderList = res.data.sortfolderList;
      this.allFolderList = res.data.allFolderList;
      if (this.selectType === "reportName") {
        //搜索仪表板的时候默认搜索所有文件夹内的仪表板，默认选中全部仪表板
        this.isactive = "";
      } else if (this.selectType === "fileName") {
        //搜索仪表板文件夹的时候，默认选中第一个仪表板文件夹
        if (this.allFolderList.length > 0) {
          this.isactive = this.allFolderList[0].id;
          this.$emit("getdashboardfolderid", this.allFolderList[0]);
        }
      }
    },
    //对文件夹设置置顶接口
    async setTopFolder(id) {
      var params = {
        folderId: id,
        isTop: "true",
      };
      await DashboardApi.setTopFolder(JSON.stringify(params));
      this.getDashboardFolderList();
    },
    //对文件夹取消置顶接口
    async cancelTopFolder(id) {
      var params = {
        folderId: id,
        isTop: "false",
      };
      await DashboardApi.setTopFolder(JSON.stringify(params));
      this.getDashboardFolderList();
    },
    getdashboardfolderid(value) {
      this.isactive = value.id;
      this.$emit("getdashboardfolderid", value);
    },
    // 删除文件夹
    deleteFolder(item) {
      let info = {
        id: item.id,
        name: item.name,
        type: this.$i18n.t("report_label_folder"),
      };
      this.$emit("deleteFolder", info);
    },
    // 点击编辑文件夹
    editFolder(id) {
      let info = {
        id: id,
        type: this.$i18n.t("pagecreator_page_button_edit"),
      };
      this.$emit("editFolder", info);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboardLeftMenu {
  // padding: 26px 18px;
  background-color: #fff;
  border: 1px solid rgb(220, 220, 220);
  width: 240px;
  .active {
    color: #006dcc;
  }
  .all_dashboards {
    .top_title_dashboard {
      display: flex;
      align-items: center;
      background: #f3f2f2;
      padding: 11px 20px 10px 17px;
      i {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .all_menus_dashboard {
      .each_menu {
        padding: 11px 20px 10px 17px;
        cursor: pointer;
      }
    }
  }
  .all_folders {
    height: calc(100% - 156px);
    overflow-y: auto;
    // margin-top: 15px;
    .top_title_folder {
      display: flex;
      align-items: center;
      background: #f3f2f2;
      padding: 11px 20px 10px 17px;
      i {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .all_menus_folder {
      height: calc(100% - 39px);
      overflow-y: auto;
      .top_folder_datas {
        border-bottom: 1px solid #eeeeee;
      }
      .each_folder {
        padding: 11px 20px 10px 17px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        div:first-child {
          width: 100%;
        }

        svg {
          width: 16px;
          height: 16px;
        }
        .operation_img {
          display: flex;
        }
      }
    }
  }
}
.no-data {
  text-align: center;
  margin: 20px auto;
  color: #909399;
}
</style>

<style lang="scss">
.el-popover.folderPopover {
  padding: 0 !important;
  min-width: 113px;
  .folder_operation {
    // width: 120px;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-button {
      margin-left: 0;
      padding: 8px 10px;
      font-size: 12px;
      color: #080707;
      border: none;
      text-align: left;
    }
    .el-button.is-disabled {
      color: #c0c4cc;
      border-color: #ebeef5;
    }
  }
}
</style>