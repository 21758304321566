<!--
  仪表板列表顶部组件
-->
<template>
  <div class="top_search">
    <div class="left_info">
      <div>{{ $t("label.version.standard.dashboard") }}</div>
      <div class="folder-name">
        {{ tableType }}
      </div>
      <div>{{ totalnum }}{{ $t("label.file.items") }}</div>
    </div>
    <div class="right_search_butt">
      <el-input
        @change="changeSelectValue"
        @keyup.enter.native="searchEnter"
        v-model="form.keyWord"
        :placeholder="$t('label_tabpage_findz') + tableType + '...'"
        class="input-with-select right-input"
      >
        <el-select
          v-model="selectFileValue"
          slot="prepend"
          :placeholder="$t('label_tabpage_pleaseSelectz')"
          @change="searchFolder"
        >
          <el-option
            v-for="item in selectFile"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-input>
      <svg
        class="icon searchBtn"
        aria-hidden="true"
        @click="searchEnter"
      >
        <use href="#icon-search"></use>
      </svg>
      <div class="all_butts">
        <el-button type="primary" size="mini" @click="createDashBoard">{{
          $t("label.dashboard.new")
        }}</el-button>
        <el-button type="primary" size="mini" @click="createDashBoardFolder">{{
          $t("label.newdashboard.folder")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalnum: {
      type: Number,
      default: 0,
    },

    folderInformation: {
      type: Object,
      default: ()=>({}),
    },
  },
  data() {
    return {
      form: {
        keyWord: "", //搜素数据
      },
      // folderName: "", //文件夹名字
      selectType: "reportName", //选择文件夹或者是仪表板
      selectFileValue: "reportName", //选择文件夹或者是仪表板进行搜索
      // 选择文件搜索数据
      selectFile: [
        {
          id: "fileName",
          name: this.$i18n.t("label.foldername"),
        },
        {
          id: "reportName",
          name: this.$i18n.t("label.newdashboardpage.name"),
        },
      ],
    };
  },
  computed: {
    tableType: function () {
      return this.folderInformation.name
        ? this.folderInformation.name
        : this.$i18n.t("label.dashboard.all");
    },
  },
  methods: {
    // 修改搜索内容
    changeSelectValue(){
      this.$emit("changeSelectValue", this.form.keyWord, this.selectType);
    },
    // 回车搜索
    searchEnter() {
      this.$emit("searchEnter", this.form.keyWord, this.selectType);
    },
    // 选择报表/仪表板搜索事件
    searchFolder(val) {
      this.selectType = val;
    },
    // 显示新建仪表板弹框
    createDashBoard() {
      this.$emit("createDashBoard");
    }, //新建仪表板文件夹
    createDashBoardFolder() {
      let info = {
        id: "",
        type: this.$i18n.t("label.brief.new"),
      };
      this.$emit("createDashBoardFolder", info);
    },
  },
};
</script>

<style lang="scss" scoped>
.top_search {
  background: #f8f8f8;
  padding: 10px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cbc6c6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  .folder-name {
    font-size: 16px;
    font-weight: bold;
  }
  .left_info {
    color: #666666;
    display: flex;
    flex-direction: column;
    color: #222222;
  }
  .right_search_butt {
    display: flex;
    align-items: center;
    .right-input {
      margin-right: 12px;
    }
    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
      width: 270px;
    }
    ::v-deep .el-input {
      font-size: 12px;
      width: 120px;
    }
    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
      width: 270px !important;
    }
    ::v-deep .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
    ::v-deep .el-input-group__prepend {
      .el-input__inner {
        width: 120px !important;
      }
    }

    ::v-deep .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
    
    .all_butts {
      display: flex;
    }
  }
}
.searchBtn {
  width: 16px;
  color: #888888;
  position: relative;
  left: -40px;
  margin: 0 0px;
  cursor: pointer;
  font-size: 14px;
}
</style>