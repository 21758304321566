<template>
  <div class="box">
    <!-- 顶部菜单 -->
    <Dashboard-top
      @createDashBoardFolder="createDashBoardFolder"
      @createDashBoard="createDashBoard"
      @searchEnter="searchEnter"
      @changeSelectValue="changeSelectValue"
      :totalnum="totalnum"
      :folderInformation="folderInformation"
    />
    <!-- 下面内容 -->
    <div class="bottom_center">
      <!-- 左侧菜单 -->
      <Dashboard-left-menu
        ref="DashboardLeftMenuRef"
        :selectContent="selectContent"
        :selectType="selectType"
        @getdashboardfolderid="getdashboardfolderid"
        @deleteFolder="deleteDashBoardAlert"
        @editFolder="createDashBoardFolder"
      />
      <!-- 右侧表格 -->
      <Dashboard-right-table
        ref="dashboardRightTable"
        :tableData="tableData"
        :totalnum="totalnum"
        :allFolderList="
          $refs.DashboardLeftMenuRef
            ? $refs.DashboardLeftMenuRef.allFolderList
            : []
        "
        :loading="loading"
        @updateDashBoard="updateDashBoard"
        @deleteDashBoardAlert="deleteDashBoardAlert"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <!-- 添加新建仪表板 -->
    <DashboardNewBuild
      ref="DashboardNewBuildRef"
      :isShowCrateDashBoardAlert="isShowCrateDashBoardAlert"
      :dashboardInfos="dashboardInfos"
      :titleType="titleType"
      :dashboardfolderid="dashboardfolderid"
      @getdashboardfolderid="getdashboardfolderid"
      @createDashBoardCancel="createDashBoardCancel"
    />
    <!-- 删除仪表板弹框 -->
    <Components-del
      :componentsDelTo="componentsDelTo"
      :type="deleteType"
      :name="name"
      @ComponentsDelCancle="componentsDelCancle"
      @ComponentsDelConfirm="ComponentsDelConfirm"
    />
    <!-- 新建仪表板文件夹弹框 -->
    <New-folder
      :newFolderTo="newFolderTo"
      :dashboardfolderid="dashboardfolderid"
      :type="type"
      @newFolderCancel="newFolderCancel"
      @saveDashboardFolder="saveDashboardFolder"
      @deleteFolder="deleteDashBoardAlert"
    />
  </div>
</template>

<script>
/**
 * 仪表板
 */
import NewFolder from "./components/NewFolder";
import DashboardTop from "./components/DashboardTop";
import DashboardLeftMenu from "./components/DashboardLeftMenu";
import DashboardRightTable from "./components/DashboardRightTable";
import DashboardNewBuild from "./components/DashboardNewBuild";
import ComponentsDel from "../../dashboardObject/dashboardIndex/components/ComponentsDel"; //删除弹窗
import * as request from "../api.js";
import mixin from "@/mixin/homepageAndDashboardMixin.js";
import * as types from "@/store/mutations-types";
import debounce from "lodash.debounce";

export default {
  components: {
    DashboardLeftMenu,
    DashboardRightTable,
    DashboardNewBuild,
    ComponentsDel,
    NewFolder,
    DashboardTop,
  },
  mixins: [mixin],
  data() {
    return {
      isShowCrateDashBoardAlert: false, //是否显示新建仪表板
      tableData: [], //表格数据
      name: "", //删除仪表板接口的仪表板名字
      deleteType: "", //删除类型：仪表板/文件夹
      componentsDelTo: false, //是否显示删除弹框
      deleteId: "", //删除仪表板id
      newFolderTo: false, //默认不显示新建仪表板文件夹弹框
      dashboardfolderid: "recentDashboard", //文件夹的id
      selectContent: "", //搜索内容
      selectType: "", //对仪表板搜索或者文件夹搜索
      dashboardPageSize: "10", //仪表板表格每页数量
      dashboardPageNum: "1", //仪表板表格当前页数
      type: "", // 文件夹保存类型：新增、修改
      totalnum: 0, //总条数
      folderInformation: {
        id: "recentDashboard",
        name: this.$i18n.t("Dashboard_View_RecentlyView"),
      }, //文件夹名字和id
      loading: false, // 仪表板列表加载loading
    };
  },
  mounted() {
    this.getDashboardList();
    document.title = this.$setTitle(
      this.$i18n.t("label.version.standard.dashboard")
    );
    this.$store.commit(
      types.SET_TAB_NAME,
      this.$i18n.t("label.version.standard.dashboard")
    );
  },
  methods: {
    // 新建弹框
    createDashBoard() {
      this.isShowCrateDashBoardAlert = true;
      this.$refs.DashboardNewBuildRef.chooseSelectFolder();
    },
    // 获取仪表板文件夹列表
    async getDashboardList() {
      this.loading = true;
      //有搜索内容页数为1
      let params = {
        name: this.selectContent, //搜索内容
        dashboardfolderid: this.dashboardfolderid,
        islightning: "true",
        page: this.dashboardPageNum,
        pageSize: this.dashboardPageSize,
      };
      let data = await request.getDashboardList(params);
      this.loading = false;
      this.tableData = data.data.listDashboard;
      this.totalnum = data.data.totalnum;
    },
    //删除仪表板
    ComponentsDelConfirm: debounce(
      async function () {
        let params = {
          id: this.deleteId,
        };
        if (
          this.deleteType === this.$i18n.t("label.version.standard.dashboard")
        ) {
          // 删除仪表板
          await request.deleteDashboard(params);
          this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
          this.$router.push({
            path: "/dashboardObject/dashBoardList",
          });
          this.getDashboardList();
        } else {
          // 删除文件夹
          let data = await request.deleteDashboardFolder(params);
          if (data && data.result) {
            this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
            this.$refs.DashboardLeftMenuRef.getDashboardFolderList();
            this.$refs.DashboardNewBuildRef.getDashboardFolderList(); // 新建仪表板组件重新获取文件夹列表
          }
          this.newFolderTo = false;
        }
        this.componentsDelCancle();
      },
      3000,
      { leading: true, trailing: false }
    ),
    //点击文件夹切换传送文件夹id
    getdashboardfolderid(value) {
      this.dashboardfolderid = value.id;
      this.folderInformation = value;
      // this.selectContent = "";
      this.dashboardPageNum = "1"; //切换文件夹页数为1
      // 切换文件夹时清空当前排序方式
      this.$refs.dashboardRightTable.$refs.filterTable.clearSort();
      this.getDashboardList();
    },
    changeSelectValue(value, type){
      this.selectContent = value; //关键字
      this.selectType = type; //仪表板or文件夹
    },
    //搜索仪表板列表,或者文件夹列表,reportName类型为仪表板，fileName为文件夹
    searchEnter(value, type) {
      this.selectContent = value; //关键字
      this.selectType = type; //仪表板or文件夹
      if (this.selectType === "reportName") {
        // 在哪个菜单下执行的搜索，就展示哪个菜单下匹配的结果&&只搜索仪表板名称，不进行仪表板文件夹搜索 type="reportName"
        this.dashboardPageNum = "1"; //搜索仪表板列表页数为1
        this.getDashboardList();
      } else {
        // 文件夹名称搜索 type="fileName"
        setTimeout(() => {
          this.$refs.DashboardLeftMenuRef.getDashboardFolderList(); // 新建仪表板组件重新获取文件夹列表
          //搜索文件夹，清空搜索条件，显示列表中第一个文件夹中所有的仪表板
          this.selectContent = "";
          this.getDashboardList();
        });
      }
    },
    //更改仪表板表格每页数量
    handleSizeChange(val) {
      this.dashboardPageSize = val;
      this.getDashboardList();
    },
    //更改仪表板表格页数
    handleCurrentChange(num) {
      this.dashboardPageNum = num;
      this.getDashboardList();
    },
    // 显示新建仪表板文件夹弹框
    createDashBoardFolder(info) {
      this.dashboardfolderid = info.id;
      this.type = info.type;
      this.newFolderTo = true;
    },
    //取消新建仪表板文件夹弹框
    newFolderCancel() {
      this.newFolderTo = false;
    },
    // 文件夹保存后
    saveDashboardFolder() {
      this.newFolderTo = false;
      this.$refs.DashboardLeftMenuRef.getDashboardFolderList(); // 重新获取文件夹列表
      this.$refs.DashboardNewBuildRef.getDashboardFolderList(); // 新建仪表板组件重新获取文件夹列表
    },
    // 显示重命名仪表板弹框
    updateDashBoard(item, type) {
      this.isShowCrateDashBoardAlert = true;
      this.dashboardInfos = item;
      this.titleType = type;
    },
    // 取消新建仪表板弹框
    createDashBoardCancel() {
      this.isShowCrateDashBoardAlert = false;
      this.getDashboardList();
    },
    // 显示删除仪表板弹框
    deleteDashBoardAlert(info) {
      this.componentsDelTo = true;
      this.deleteType = info.type;
      this.name = info.name;
      this.deleteId = info.id;
    },
    //取消仪表板弹框
    componentsDelCancle() {
      this.componentsDelTo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  padding: 10px;
  font-size: 12px !important;
  color: #080707 !important;
  display: flex;
  flex-direction: column;
  .bottom_center {
    background-color: #ffffff;
    height: calc(100% - 70px);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>